import React from 'react';
import styled from 'styled-components';
import { FaWhatsapp } from 'react-icons/fa';

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
`;

const Button = styled.a`
  width: 60px;
  height: 60px;
  background-color: #25d366; // WhatsApp green
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: #128C7E; // Darker WhatsApp green on hover
    transform: translateY(-3px);
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.4);
  }

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
    font-size: 25px;
  }
`;

const ConsultationText = styled.span`
  background-color: #007bff; // Blue background for text
  color: #fff;
  padding: 8px 12px;
  border-radius: 20px;
  font-size: 14px;
  margin-top: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  text-align: center;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 6px 10px;
  }
`;

const WhatsAppButton = ({ phoneNumber }) => {
  const handleClick = () => {
    window.open(`https://wa.me/${phoneNumber}`, '_blank');
  };

  return (
    <ButtonContainer>
      <Button onClick={handleClick}>
        <FaWhatsapp />
      </Button>
      <ConsultationText>Click for Free Consultation</ConsultationText>
    </ButtonContainer>
  );
};

export default WhatsAppButton;