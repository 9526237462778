import React from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './services.css';
import claimImg from './Images/claim-img.png';
import claimImg2 from './Images/claimImage2.png';
import claimImg3 from './Images/claimImage3.png';
import claimImg4 from './Images/claimImage4.png';

const services = [
  {
    title: "Claim Assessment & Filing",
    icon: claimImg,
    description: (
      <>
        <strong>Initial Consultation:</strong> We provide a thorough evaluation of your situation and advise on the best course of action. <br /><strong>Document Preparation:</strong> Assistance with gathering and organizing all necessary documents for a smooth claim submission.<br /> <strong>Claim Filing:</strong> Professional support in filing claims accurately and promptly.
      </>
    )},
  {
    title: "Policy Review & Consultation",
    icon: claimImg3,
    description: (
      <>
        <strong>Policy Review:</strong> Detailed analysis of your insurance policies to ensure adequate coverage. <br /><br /><strong>Consultation Services:</strong> Personalized advice on insurance policies, coverage options, and potential claims issues.<br />
      </>
    )},
  {
    title: "Claim Dispute Resolution",
    icon: claimImg4,
    description: (
      <>
        <strong>Denial Analysis:</strong> Examination of rejected/repudiated claims to identify grounds for appeal within company or filing complaint in court.<br /><br /><strong>Appeal Process:</strong> Expert assistance in preparing and submitting appeals for denied claims
      </>
    )}
];

function ServicesCarousel() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/contact');
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <section className="servicesSection">
      <h2>Our Services</h2>
      <div className="carouselContainer">
        <Slider {...settings}>
          {services.map((service, index) => (
            <div key={index} className="serviceSlide">
              <div className="serviceContent">
                <img src={service.icon} alt={service.title} className="serviceIcon" />
                <h3>{service.title}</h3>
                <p style={{ textAlign: 'justify' }}>{service.description}</p>
                <button className="bookNowButton" onClick={handleClick}>Book Now</button>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default ServicesCarousel;