import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Container, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import './services.css';

// Custom styles
const CustomAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: '#f5f6f7',
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2),
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const faqData = [
    {
      question: "What is a Free Look Period in a policy bond?",
      answer: "A Free Look Period in Life /Health insurance is a designated time frame during which a new policyholder can review their insurance policy in detail. If a policyholder finds any discrepancy in the policy and do not wish to continue it, he/she may do approach the Insurance Company for full refund within 30 days of delivery of the policy bond (Earlier the time frame was 15 days and 30 days incase of electronic policies obtained through distance mode. However, as per new IRDA Circular 2024, uniform 30 days Free Look Period has been granted) "
    },
    {
      question: "How do we help in case of Rejection of your claim?",
      answer: "Assessment of any case is very important before proceeding. Once you assign us to take up your issue, we will assess all your documents submitted to the insurance company and discover the reason for Rejection of your Insurance Claim. Upon the findings, we will help to re-submit the claim with proper documentations and approval of the claim."
    },
    {
      question: "How do we help in case of Repudiation/Denial of your claim?",
      answer: "Once you assign us to take up your issue, we will assess all your documents submitted to the insurance company and discover the reason for Denial of your Insurance Claim. If your case appears clean & genuine, we will come along for justice."
    },
    {
      question: "What is a Grace Period in any Policy?",
      answer: "Grace period is the time given by the insurance company after the due date to pay your premium before the policy is lapsed. Generally, 30 days grace period is provided by the insurer"
    },
    {
      question: "How do we take forward your issue upon assignment to us?",
      answer: "First and foremost, an online registration form needs to be filled in. Upon submitting the same, our team will get in touch with you to understand your concern. A telephonic discussion directly with the Head Legal and Founder, Ms. Preeti Chaturvedi will be set up as per your convenience. Basis the Legal Consultation and decision taken by you, we will approach Ombudsman Office or Consumer Commissions, to resolve the grievance within a stipulated time."
    },
    {
      question: "What is our approach in case of mis-selling of a policy?",
      answer: "If you are a victim of mis-selling of a policy, we will check what all evidences you have like email conversations, call recordings, messages, etc. We will help you draft the email and attach all the evidences and send to the Insurance Company for resolving the complaint. If there is no fruitful outcome, we will then take the matter to appropriate Forum for justice. "
    },
    {
      question: "How long will it take to resolve your issue upon assignment?",
      answer: "We, having a Customer Centric Approach, totally understand the mental pressure you are going through if your claim is not settled. Hence, we have a TAT to each step for timely resolution of your matter. Once you have submitted all your complete documents with us, we will set up a call with our Founder within 3 working days. All the documents preparation for approaching before the Company/Ombudsman/Commission will be done within 7 working days. Once documents are filed, Ombudsman’s / Commission’s process will start as per the procedure. We ensure that no date is left unattended so that matter is settled as soon as possible. Approximately, matter is decided within 1-2 months if Ombudsman office is approached and 6-8 months when Commission is approached."
    },
    // {
    //   question: "What is the professional fee charged by us?",
    //   answer: "We provide 3 free consultations with our Founder & Legal Head. If the case is assigned to us, we charge only Rs 6000 just for documentations and miscellaneous expenses in an appropriate forum. This fees is charged by us only after filing of the case. Once case is decided in your favour, we charge just 6% of the awarded amount."
    // },
    // {
    //   question: "Is the registration fee refundable or non-refundable?",
    //   answer: "Total registration fee charged by us is Rs 100 only which is non-refundable."
    // },
    {
      question: "What are the formalities for a death claim?",
      answer: (
        <>
          <Typography variant="body1" component="div" sx={{ marginBottom: '1rem' }}>
            When a person with a life insurance policy dies, a claim intimation should be sent to the insurance company as early as possible by the assignee or the nominee. The claim intimation form should contain information like date, place, and cause of death.
          </Typography>
          <Typography variant="body1" component="div" sx={{ marginBottom: '1rem' }}>
            The insurance company will ask for the following documents:
          </Typography>
          <Typography variant="body1" component="div">
            a) Filled up claim form.
            <div style={{ margin: '0.5rem 0' }} />
            b) Certificate of Death
            <div style={{ margin: '0.5rem 0' }} />
            c) Original Policy Bond
            <div style={{ margin: '0.5rem 0' }} />
            d) Deeds of assignment, if any
            <div style={{ margin: '0.5rem 0' }} />
            e) Copy of Id proofs of nominee
            <div style={{ margin: '0.5rem 0' }} />
            f) Bank Statement
          </Typography>
        </>
      ),
    }
  ];

const FAQSection = () => {
  return (
    <Container maxWidth="md" sx={{ padding: '2rem 0' }}>
    <section className="faqSection"><h2>Frequently Asked Questions</h2></section>
      {faqData.map((faq, index) => (
        <CustomAccordion key={index}>
          <CustomAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index + 1}-content`}
            id={`panel${index + 1}-header`}
          >
            <Typography variant="h6">{faq.question}</Typography>
          </CustomAccordionSummary>
          <CustomAccordionDetails>
            <Typography variant="body1" sx={{ lineHeight: 1.6 }}>
              {faq.answer}
            </Typography>
          </CustomAccordionDetails>
        </CustomAccordion>
      ))}
    </Container>
  );
};

export default FAQSection;
