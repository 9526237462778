// Handling Services menu click
document.addEventListener('DOMContentLoaded', function() {
    const servicesMenu = document.getElementById('servicesMenu');
    const dropdown = document.getElementById('dropdownMenu');
    dropdown.style.display = 'none';
    servicesMenu.addEventListener('click', function() {
        if (dropdown.style.display === 'none') {
            dropdown.style.display = 'block';
        } else {
            dropdown.style.display = 'none';
        }
    });

    document.addEventListener('click', function(event) {
        if (!servicesMenu.contains(event.target)) {
            dropdown.style.display = 'none';
        }
    });
});

document.addEventListener('DOMContentLoaded', function() {
    const aboutUsLink = document.getElementById('aboutUs');
    aboutUsLink.addEventListener('click', function(event) {
        event.preventDefault();
        const targetSection = document.getElementById('about-us-section');

        if (targetSection) {
            const offsetTop = targetSection.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({
                top: offsetTop,
                behavior: 'smooth'
            });
        }
    });
});

