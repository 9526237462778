import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import './ContactForm.css';
import { useSnackbar } from 'notistack';
const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    policyType: '',
    complaint: ''
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    debugger;
    console.log(formData);
    try {
      const response = await emailjs.send(
      'service_dlmbu4o',
      'template_7nw9zfw',
      formData,
      '_kOcamYqGswd3priA'
      );
      setFormData({name: '',
        email: '',
        phone: '',
        policyType: '',
        complaint: ''});
      enqueueSnackbar('Email has been sent successfully!', { 
        variant: 'success',
      });
    } catch(e){
      enqueueSnackbar('There was an error sending the email. Please try again later.', {
        variant: 'error',
      });
        console.error('Error sending email:', e);
    }
  };

  return (
    <div className="contact-form-container">
      <h2>Contact Us</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone Number</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="policyType">Policy Type</label>
          <select
            id="policyType"
            name="policyType"
            value={formData.policyType}
            onChange={handleChange}
            required
          >
            <option value="">Select a policy type</option>
            <option value="Claim Assessment & Filing">Claim Assessment & Filing</option>
            <option value="Policy Review & Consultation">Policy Review & Consultation</option>
            <option value="Claim Dispute Resolution">Claim Dispute Resolution</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="complaint">Complaint</label>
          <textarea
            id="complaint"
            name="complaint"
            value={formData.complaint}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <button type="submit" className="submit-btn">Submit</button>
      </form>
    </div>
  );
};

export default ContactForm;